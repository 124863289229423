
/***********************    PARA WORD    ***********************/
Office.onReady((info) => {
    if (info.host === Office.HostType.Word) {
        // Assign event handlers and other initialization logic.
        document.getElementById("sideload-msg").style.display = "none";
        document.getElementById("app-body").style.display = "flex";
        
        // Asigna una funcion al evento clic de los botones
        document.getElementById("traducir").onclick = traducir;
        document.getElementById("changeLang").onclick = cambiar;
        document.getElementById("borrar").onclick = borrar;
        document.getElementById("inputAudio").onclick = inputAudio;
        document.getElementById("outputAudio").onclick = outputAudio;

        // Eventos clic de la lista para cambiar de idiomas
        document.getElementById("euskera").onclick = euskera;
        document.getElementById("ingles").onclick = ingles;
        document.getElementById("castellano").onclick = castellano;
        document.getElementById("frances").onclick = frances;
        document.getElementById("catalan").onclick = catalan;
        document.getElementById("gallego").onclick = gallego;

        // Evento change del select 
        document.getElementById("lenguajes").onchange = verificarIdioma;

        var texto = document.getElementById("inputText");

        // Selecciona todo el texto al poner el foco en los textareas
        texto.addEventListener("focus", function () {
            texto.select();
        })

        verificarIdioma();
    }
});

async function traducir() {
    await Word.run(async (context) => {
        const API_URL = "https://api.trebesrv.com/translation/v1/translate";
        let select = $('#lenguajes').val();
        let language = select.split("-"); // Divide los valores de la select

        let inputText = $('#inputText').val(); // Texto de entrada (textarea)
        let apiKey = $('#txtApi').val();

        if (inputText !== "") {
            // Request options    
            let requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'apikey': apiKey,
                },
                body: JSON.stringify({
                    sourcelanguage: language[0],
                    targetlanguage: language[1],
                    text: inputText,
                    getsentences: true,
                }),
            };

            // Send request    
            fetch(API_URL, requestOptions)
                .then(data => data.json())
                .then(data => {
                    $("#outputText").val(data.message || data.error); // Muestra la traduccion en el input de salida (textarea)
                    copiar();
                })
                .catch((error) => {
                    $("#outputText").val(error); // Muestra el error en el input de salida  
                });
        }
        await context.sync();
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}

async function copiar() {
    await Word.run(async (context) => {
        let texto = $('#outputText');    
        navigator.clipboard.writeText(texto.val()); // Copia el texto del outputText
        await context.sync();
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}

async function verificarIdioma() {
    await Word.run(async (context) => {
        let select = $('#lenguajes').val();
        let lang = select.split("-");
       
        // Muestra o oculta el altavoz del inputaudio
        if (lang[0] !== 'es' && lang[0] !== 'eu')
        {
            $('#inputAudio').css("display", "none");
        }
        else
        {
            $('#inputAudio').css("display", "block");
        }

        // Muestra o oculta el altavoz del outputaudio
        if (lang[1] !== 'es' && lang[1] !== 'eu')
        {
            $('#outputAudio').css("display", "none");
        }
        else
        {  
            $('#outputAudio').css("display", "block");
        }

        await context.sync();
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}

// Variables para controlar el estado del audio

let audio = null; // Instancia de AudioContext
let source = null; // Fuente del audio
let buffer = null; // Buffer del audio decodificado 
let isPlaying = false; // Indica si el audio esta reproduciendose
let startTime = 0; // Almacena el tiempo de inicio para reanudar desde esa posi

// Reinicia el contexto de audio
function resetAudioContext() {

    if (audio) {
        audio.close();
    }
    audio = new AudioContext();
}

// Decodifica y configura el buffer de audio a partir de un arrayBuffer
async function prepareAudio(arrayBuffer) {
    resetAudioContext();
    buffer = await audio.decodeAudioData(arrayBuffer);
}

// Función para establecer el cursor en "wait"
function setCursorWait() {
    $('#inputAudio').css("cursor", "wait"); 
    $('#outputAudio').css("cursor", "wait"); 
    $("#body").addClass('wait-cursor');
}
  
// Función para restaurar el cursor original
function restoreCursor() {
    $('#inputAudio').css("cursor", "pointer");
    $('#outputAudio').css("cursor", "pointer");  
    $("#body").removeClass('wait-cursor');
}
  

async function inputAudio() {       
    await Word.run(async (context) => {         
        // Establece los atributos de la imagen del boton
        var imagen = document.getElementById("play");        
        imagen.src = "../../assets/pause.jpg";  
        $('#play').css("width", "40px")
        $('#play').css("height", "40px")
        $('#play').css("position", "absolute")
        $('#play').css("top", "-5px")
        $('#play').css("left", "0px")   
        $('#play').css("margin-left", "35px")     

        // Si ya esta reproduciendo un audio lo para
        if (isPlaying)
        {
            source.stop(0);
            isPlaying = false;
            startTime = 0;
            return;
        }

        let select = $('#lenguajes').val();
        let language = select.split("-"); // Divide los valores de la select

        let voz = '';

        let inputText = $('#inputText').val(); // Texto de entrada (textarea)
        let apiKey = $('#txtApi').val();

        const API_URL = "https://api.trebesrv.com/synthesis/v1/jobs/";
        
        if (language[0] === 'es')
        {
            voz = 'mikel';
        }
        else if (language[0] === 'eu')
        {
            voz = 'nerea';
        }

        // Request options            
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': apiKey,
            },
            body: JSON.stringify({
                normalize: true,
                voice: voz,
                text: inputText,
            }),
        };         
           
        // Send request             
        let jobId = await fetch(API_URL, requestOptions)
            .then(res => res.json())                
            .then(data => {   
                console.log("[INFO] JobId: " + data.id);
                return data.id;
            })
            .catch((error) => {
                console.log("[ERROR] " + error); 
                return null;
            });       

        if (jobId === null) {
            return null;
        }             

        // New request body 
        requestOptions = { 
            method: 'GET', 
            headers: { 
                apikey: apiKey, 
            }, 
        }

        // Waiting status 
        let statusCode = 0;

        // Continue requesting if job is being processed 
        while (statusCode === 0 || statusCode === 1) { 
            setCursorWait();
            // Wait 2s before requesting 
            await new Promise(r => setTimeout(r, 2000));
            
            // Get current status code
            let status = await fetch(API_URL + jobId + "/info", requestOptions) 
                .then(res => res.json())
                .then(data => {
                    console.log("[INFO] Status:" + data.status);
                    return data.status; // Return status code
                })
                .catch((error) => {
                    console.log(error);
                    return null; 
                })

            // Update status code
            statusCode = status === null ? -1 : status;
        } 

        // Return null if an error ocurred
        if (statusCode === -1)
        {
            console.log("[INFO] Job not processed");     
            return null;
        } 

        // Show synthetized audio if not error ocurred
        else if(statusCode === 2) {
            console.log("[INFO] Ready to synthetize");

            // Request body
            requestOptions = {
                method: 'GET',
                headers: {
                    apikey: apiKey,
                },
            }
            // Get audio source and show audio
            let createAudio = async () => {            
                let arrayBuffer = await fetch(API_URL + jobId + "/result", requestOptions)
                    .then(res => res.arrayBuffer())
                    .then(data => {
                        if (data !== null) {
                            console.log("[INFO] Data: " + data);
                            return data;
                        }
                        return null;
                    })
                    .catch((error) => { 
                        console.log("[ERROR] " + error); 
                        alert("Unexpected error."); 
                        return null; // Return nothing 
                    });

                try {
                    // Validation
                    if (arrayBuffer === null)
                    {
                        console.log("[INFO] ArrayBuffer: NULL");
                        throw Error("Buffer not retrieved");
                    }
                    
                    await prepareAudio(arrayBuffer);                    
                          
                    // Verifica si el buffer existe y no se está reproduciendo ningún audio actualmente
                    if (buffer && !isPlaying)
                    {
                        // Crea una nueva fuente de audio
                        source = audio.createBufferSource();
                        source.buffer = buffer;
                        source.connect(audio.destination);
                        
        
                        startTime = 0; // Ajusta el tiempo de inicio para reanudar desde la posición correcta
                        source.start(0, startTime); // Se utiliza startTime para reanudar desde la posición pausada
                        restoreCursor();                       
                        isPlaying = true;
                    }              
                    
                    // Establece los nuevos atributos de la imagen cuando acaba de reproducirse el audio
                    source.onended = function() {
                        isPlaying = false;
                        var imagen = document.getElementById("play");
                        imagen.src = "../../assets/play.png";
                        $('#play').css("width", "28px");
                        $('#play').css("height", "28px");
                        $('#play').css("position", "absolute");
                        $('#play').css("top", "1px");
                        $('#play').css("left", "7px");                        
                    };
                    
                    
                } catch (error) {
                    console.log("[ERROR] " + error); 
                    alert("Unexpected error."); 
                    return null; // Return nothing 
                }                 
            }           
            
            // Prepare generated audio
            createAudio();
        }
        await context.sync();
    })
    .catch(function(error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    })
}

async function outputAudio() {       
    await Word.run(async (context) => {        
        // Establece los atributos de la imagen del boton
        var imagen = document.getElementById("play2");        
        imagen.src = "../../assets/pause.jpg";  
        $('#play2').css("width", "40px")
        $('#play2').css("height", "40px")
        $('#play2').css("position", "absolute")
        $('#play2').css("bottom", "29px")
        $('#play2').css("right", "25px")
        
        // Si ya se está reproduciendo un audio lo para
        if (isPlaying)
        {
            source.stop(0);
            isPlaying = false;
            startTime = 0;
            return;
        }

        let select = $('#lenguajes').val();
        let language = select.split("-"); // Divide los valores de la select

        let voz = '';

        let outputText = $('#outputText').val(); // Texto de entrada (textarea)
        let apiKey = $('#txtApi').val();

        const API_URL = "https://api.trebesrv.com/synthesis/v1/jobs/";
        
        if (language[1] === 'es')
        {
            voz = 'mikel';
        }
        else if (language[1] === 'eu')
        {
            voz = 'nerea';
        }

        // Request options            
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'apikey': apiKey,
            },
            body: JSON.stringify({
                normalize: true,
                voice: voz,
                text: outputText,
            }),
        };         
           
        // Send request             
        let jobId = await fetch(API_URL, requestOptions)
            .then(res => res.json())                
            .then(data => {   
                console.log("[INFO] JobId: " + data.id);
                return data.id;
            })
            .catch((error) => {
                console.log("[ERROR] " + error); 
                return null;
            });       

        if (jobId === null) {
            return null;
        }             

        // New request body 
        requestOptions = { 
            method: 'GET', 
            headers: { 
                apikey: apiKey, 
            }, 
        }

        // Waiting status 
        let statusCode = 0;

        // Continue requesting if job is being processed 
        while (statusCode === 0 || statusCode === 1) { 
            setCursorWait();
            // Wait 2s before requesting 
            await new Promise(r => setTimeout(r, 2000));
            
            // Get current status code
            let status = await fetch(API_URL + jobId + "/info", requestOptions) 
                .then(res => res.json())
                .then(data => {
                    console.log("[INFO] Status:" + data.status);
                    return data.status; // Return status code
                })
                .catch((error) => {
                    console.log(error);
                    return null; 
                })

            // Update status code
            statusCode = status === null ? -1 : status;
        } 

        // Return null if an error ocurred
        if (statusCode === -1)
        {
            console.log("[INFO] Job not processed");     
            return null;
        } 

        // Show synthetized audio if not error ocurred
        else if(statusCode === 2) {
            console.log("[INFO] Ready to synthetize");

            // Request body
            requestOptions = {
                method: 'GET',
                headers: {
                    apikey: apiKey,
                },
            }
            // Get audio source and show audio
            let createAudio = async () => {            
                let arrayBuffer = await fetch(API_URL + jobId + "/result", requestOptions)
                    .then(res => res.arrayBuffer())
                    .then(data => {
                        if (data !== null) {
                            console.log("[INFO] Data: " + data);
                            return data;
                        }
                        return null;
                    })
                    .catch((error) => { 
                        console.log("[ERROR] " + error); 
                        alert("Unexpected error."); 
                        return null; // Return nothing 
                    });

                try {
                    // Validation
                    if (arrayBuffer === null)
                    {
                        console.log("[INFO] ArrayBuffer: NULL");
                        throw Error("Buffer not retrieved");
                    }
                    
                    await prepareAudio(arrayBuffer);                    
                          
                    // Verifica si el buffer existe y no se está reproduciendo ningún audio actualmente
                    if (buffer && !isPlaying)
                    {
                        // Crea una nueva fuente de audio
                        source = audio.createBufferSource();
                        source.buffer = buffer;
                        source.connect(audio.destination);
                        
        
                        startTime = 0; // Ajusta el tiempo de inicio para reanudar desde la posición correcta
                        source.start(0, startTime); // Se utiliza startTime para reanudar desde la posición pausada
                        restoreCursor();                       
                        isPlaying = true;
                    }              
                    
                    // Establece los nuevos atributos de la imagen cuando acaba de reproducirse el audio
                    source.onended = function() {
                        isPlaying = false;
                        var imagen = document.getElementById("play2");
                        imagen.src = "../../assets/play.png";
                        $('#play2').css("width", "28px");
                        $('#play2').css("height", "28px");
                        $('#play2').css("position", "absolute");
                        $('#play2').css("bottom", "35px")
                        $('#play2').css("right", "30px")
                    };                    
                    
                } catch (error) {
                    console.log("[ERROR] " + error); 
                    alert("Unexpected error."); 
                    return null; // Return nothing 
                }                 
            }           
            
            // Prepare generated audio
            createAudio();
        }
        await context.sync();
    })
    .catch(function(error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    })
}

async function cambiar() {
    await Word.run(async (context) => {
        let select = $("#lenguajes");
        let lang = select.val().split("-"); // Divide los valores de la select
        let inText = $("#inputText").val();
        let outText = $("#outputText").val();
        
        select.val(lang[1] + "-" + lang[0]); // Intercambia de posicion el idioma

        $("#inputText").val(outText); // Intercambia el contenido de los textarea
        $("#outputText").val(inText);

        verificarIdioma();

        await context.sync();
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}

async function borrar() {
    await Word.run(async (context) => {
        $('#inputText').val("");
        $('#outputText').val("");

        await context.sync();
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}

async function euskera() {
    await Word.run(async (context) => {
        // Cambia todos los textos a euskera
        document.getElementById("titulo").innerText = "Testu bat itzuli";
        document.getElementById("idioma").innerText = "Hautatu hizkuntza:"
        document.getElementById("inputText").placeholder = "Testua sartu";
        document.getElementById("traducir").innerText = "Itzuli";
        document.getElementById("borrar").innerText = "Garbitu";

        // Cambia todas las opciones de traduccion a euskera
        document.getElementById("es_eu").innerText = "Gaztelania - Euskara";
        document.getElementById("eu_es").innerText = "Euskara - Gaztelania";
        document.getElementById("eu_en").innerText = "Euskara - Ingelesa";
        document.getElementById("en_eu").innerText = "Ingelesa - Euskara";
        document.getElementById("eu_fr").innerText = "Euskara - Frantsesa";
        document.getElementById("fr_eu").innerText = "Frantsesa - Euskara";
        document.getElementById("es_en").innerText = "Gaztelania - Ingelesa";
        document.getElementById("en_es").innerText = "Ingelesa - Gaztelania";
        document.getElementById("es_fr").innerText = "Gaztelania - Frantsesa";
        document.getElementById("fr_es").innerText = "Frantsesa - Gaztelania";
        document.getElementById("es_ca").innerText = "Gaztelania - Katalana";
        document.getElementById("ca_es").innerText = "Katalana - Gaztelania";
        document.getElementById("ca_gl").innerText = "Gaztelania - Galiziera";
        document.getElementById("gl_es").innerText = "Galiziera - Gaztelania";

        await context.sync();
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}

async function castellano() {
    await Word.run(async (context) => {
        // Cambia todos los textos a castellano
        document.getElementById("titulo").innerText = "Traducci\u00F3n de texto"; 
        document.getElementById("idioma").innerText = "Seleccionar idioma:"
        document.getElementById("inputText").placeholder = "Introducir texto";
        document.getElementById("traducir").innerText = "Traducir";
        document.getElementById("borrar").innerText = "Limpiar";

        // Cambia todas las opciones de traduccion a castellano
        document.getElementById("es_eu").innerText = "Castellano - Euskera";
        document.getElementById("eu_es").innerText = "Euskera - Castellano";
        document.getElementById("eu_en").innerText = "Euskera - Ingl\u00E9s";
        document.getElementById("en_eu").innerText = "Ingl\u00E9s - Euskera";
        document.getElementById("eu_fr").innerText = "Euskera - Franc\u00E9s";
        document.getElementById("fr_eu").innerText = "Franc\u00E9s - Euskera";
        document.getElementById("es_en").innerText = "Castellano - Ingl\u00E9s";
        document.getElementById("en_es").innerText = "Ingl\u00E9s - Castellano";
        document.getElementById("es_fr").innerText = "Castellano - Franc\u00E9s";
        document.getElementById("fr_es").innerText = "Franc\u00E9s - Castellano";
        document.getElementById("es_ca").innerText = "Castellano - Catal\u00E1n";
        document.getElementById("ca_es").innerText = "Catal\u00E1n - Castellano";
        document.getElementById("ca_gl").innerText = "Castellano - Gallego";
        document.getElementById("gl_es").innerText = "Gallego - Castellano";

        await context.sync();
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}

async function ingles() {
    await Word.run(async (context) => {
        // Cambia todos los textos a ingl�s
        document.getElementById("titulo").innerText = "Text translation";
        document.getElementById("idioma").innerText = "Select language:";
        document.getElementById("inputText").placeholder = "Introduce text";        
        document.getElementById("traducir").innerText = "Translate";
        document.getElementById("borrar").innerText = "Clear";

        // Cambia todas las opciones de traduccion a ingles
        document.getElementById("es_eu").innerText = "Spanish - Basque";
        document.getElementById("eu_es").innerText = "Basque - Spanish";
        document.getElementById("eu_en").innerText = "Basque - English";
        document.getElementById("en_eu").innerText = "English - Basque";
        document.getElementById("eu_fr").innerText = "Basque - French";
        document.getElementById("fr_eu").innerText = "French - Basque";
        document.getElementById("es_en").innerText = "Spanish - English";
        document.getElementById("en_es").innerText = "English - Spanish";
        document.getElementById("es_fr").innerText = "Spanish - French";
        document.getElementById("fr_es").innerText = "French - Spanish";
        document.getElementById("es_ca").innerText = "Spanish - Catalan";
        document.getElementById("ca_es").innerText = "Catalan - Spanish";
        document.getElementById("ca_gl").innerText = "Spanish - Galician";
        document.getElementById("gl_es").innerText = "Galician - Spanish";

        await context.sync();
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}

async function catalan() {
    await Word.run(async (context) => {
        // Cambia todos los textos a catalan
        document.getElementById("titulo").innerText = "Traducci\u00F3  de text";
        document.getElementById("idioma").innerText = "Seleccionar idioma:"
        document.getElementById("inputText").placeholder = "Introduir text";
        document.getElementById("traducir").innerText = "Traduir";
        document.getElementById("borrar").innerText = "Esborrar";

        // Cambia todas las opciones de traduccion a catalan
        document.getElementById("es_eu").innerText = "Castell\u00E0 - \u00C8uscar";
        document.getElementById("eu_es").innerText = "\u00C8uscar - Castell\u00E0";
        document.getElementById("eu_en").innerText = "\u00C8uscar - Angl\u00E8s";
        document.getElementById("en_eu").innerText = "Angl\u00E8s - \u00C8uscar";
        document.getElementById("eu_fr").innerText = "\u00C8uscar - Franc\u00E8s";
        document.getElementById("fr_eu").innerText = "Franc\u00E8s - \u00C8uscar";
        document.getElementById("es_en").innerText = "Castell\u00E0 - Angl\u00E8s";
        document.getElementById("en_es").innerText = "Angl\u00E8s - Castell\u00E0 ";
        document.getElementById("es_fr").innerText = "Castell\u00E0  - Franc\u00E8s";
        document.getElementById("fr_es").innerText = "Franc\u00E8s - Castell\u00E0";
        document.getElementById("es_ca").innerText = "Castell\u00E0 - Catal\u00E0";
        document.getElementById("ca_es").innerText = "Catal\u00E0 - Castell\u00E0";
        document.getElementById("ca_gl").innerText = "Castell\u00E0 - Gallec";
        document.getElementById("gl_es").innerText = "Gallec - Castell\u00E0";

        await context.sync();
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}

async function gallego() {
    await Word.run(async (context) => {
        // Cambia todos los textos a gallego
        document.getElementById("titulo").innerText = "Traduci\u00F3n de texto";
        document.getElementById("idioma").innerText = "Escoller a lingua:";
        document.getElementById("inputText").placeholder = "Indique o texto";
        document.getElementById("traducir").innerText = "Traducir";
        document.getElementById("borrar").innerText = "Limpar";

        // Cambia todas las opciones de traduccion a gallego
        document.getElementById("es_eu").innerText = "Castel\u00E1n - \u00C9uscaro";
        document.getElementById("eu_es").innerText = "\u00C9uscaro - Castel\u00E1n";
        document.getElementById("eu_en").innerText = "\u00C9uscaro - Ingl\u00E9s";
        document.getElementById("en_eu").innerText = "Ingl\u00E9s - \u00C9uscaro";
        document.getElementById("eu_fr").innerText = "\u00C9uscaro - Franc\u00E9s";
        document.getElementById("fr_eu").innerText = "Franc\u00E9s - \u00C9uscaro";
        document.getElementById("es_en").innerText = "Castel\u00E1n - Ingl\u00E9s";
        document.getElementById("en_es").innerText = "Ingl\u00E9s - Castel\u00E1n";
        document.getElementById("es_fr").innerText = "Castel\u00E1n - Franc\u00E9s";
        document.getElementById("fr_es").innerText = "Franc\u00E9s - Castel\u00E1n";
        document.getElementById("es_ca").innerText = "Castel\u00E1n - Catal\u00E1n";
        document.getElementById("ca_es").innerText = "Catal\u00E1n - Castel\u00E1n";
        document.getElementById("ca_gl").innerText = "Castel\u00E1n - Galego";
        document.getElementById("gl_es").innerText = "Galego - Castel\u00E1n";

        await context.sync();
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}

async function frances() {
    await Word.run(async (context) => {
        // Cambia todos los textos a frances
        document.getElementById("titulo").innerText = "Traduction de texte";
        document.getElementById("idioma").innerText = "S\u00E9lectionner la langue:"
        document.getElementById("inputText").placeholder = "Ins\u00E9rer du texte";
        document.getElementById("traducir").innerText = "Traduire";
        document.getElementById("borrar").innerText = "Effacer";

        // Cambia todas las opciones de traduccion a frances
        document.getElementById("es_eu").innerText = "Espagnol - Euskera";
        document.getElementById("eu_es").innerText = "Euskera - Espagnol";
        document.getElementById("eu_en").innerText = "Euskera - Anglais";
        document.getElementById("en_eu").innerText = "Anglais - Euskera";
        document.getElementById("eu_fr").innerText = "Euskera - Fran\u00E7ais";
        document.getElementById("fr_eu").innerText = "Fran\u00E7ais - Euskera";
        document.getElementById("es_en").innerText = "Espagnol - Anglais";
        document.getElementById("en_es").innerText = "Anglais - Espagnol";
        document.getElementById("es_fr").innerText = "Espagnol - Fran\u00E7ais";
        document.getElementById("fr_es").innerText = "Fran\u00E7ais - Espagnol";
        document.getElementById("es_ca").innerText = "Espagnol - Catalan";
        document.getElementById("ca_es").innerText = "Catalan - Espagnol";
        document.getElementById("ca_gl").innerText = "Espagnol - Galicien";
        document.getElementById("gl_es").innerText = "Galicien - Espagnol";       

        await context.sync();
    })
    .catch(function (error) {
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
            console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
    });
}
